import React from 'react';
import Meta from '../components/Meta';

export default function FourOhFourPage() {
  return (
    <>
      <Meta
        title="404 | CloudKitchens"
        description="404 | CloudKitchens"
        url="https://www.cloudkitchens.com/404"
      />
      <div className="container pt-16 md:pt-20 lg:pt-40 pb-40">
        <div className="flex justify-center text-center">
          <div>
            <h1 className="h1 color--primary-dark mb-4">404</h1>
            <p className="color--grey-5">Page not found!</p>
          </div>
        </div>
      </div>
    </>
  );
}
